import consts from '@/consts'
export default () => {
  useJsonld({
    '@context': 'https://schema.org',
    '@id': consts.websiteUrl + '#website',
    '@type': 'WebSite',
    url: consts.websiteUrl,
    name: consts.companyName,
  })
  useJsonld({
    '@context': 'https://schema.org',
    '@id': consts.websiteUrl + '#organization',
    '@type': 'Organization',
    name: consts.companyName,
    legalName: consts.companyLegalName,
    url: consts.websiteUrl,
    logo: consts.defaultLogoMark,
    subOrganization: {
      '@type': 'Organization',
      name: 'PayAnalytics by beqom',
      url: 'https://www.payanalytics.com/',
      '@id': 'https://www.payanalytics.com/#organization',
    },
    email: 'info@beqom.com',
    description: consts.companyDescription,
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress: '381 Park Avenue South',
        addressLocality: 'New York',
        addressRegion: 'NY',
        addressCountry: 'US',
        postalCode: '10016',
      },
      {
        '@type': 'PostalAddress',
        streetAddress: 'Rue de la Colombière 28',
        addressLocality: 'Nyon',
        addressRegion: 'Vaud',
        addressCountry: 'CH',
        postalCode: '1260',
      },
      {
        '@type': 'PostalAddress',
        streetAddress: '8 St. Thomas Street',
        addressLocality: 'London',
        addressCountry: 'UK',
        postalCode: 'SE1 9RR',
      },

      {
        '@type': 'PostalAddress',
        streetAddress: 'Rue de Bercy 203',
        addressLocality: 'Paris',
        addressCountry: 'FR',
        postalCode: '75012',
      },
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSupportNorthAmerica,
        contactType: 'technical support',
        areaServed: ['US', 'CA'],
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES'],
      },
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSupportWorldwide,
        contactType: 'technical support',
        areaServed: 'Worldwide',
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES', 'DE', 'FR'],
      },
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSalesNorthAmerica,
        contactType: 'sales',
        areaServed: ['US', 'CA'],
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES'],
      },
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSalesWorldwide,
        contactType: 'sales',
        areaServed: 'Worldwide',
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES', 'DE', 'FR'],
      },
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSupportNorthAmerica,
        contactType: 'customer support',
        areaServed: ['US', 'CA'],
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES'],
      },
      {
        '@type': 'ContactPoint',
        telephone: consts.phoneSupportWorldwide,
        contactType: 'customer support',
        areaServed: 'Worldwide',
        url: consts.contactUsPage,
        availableLanguage: ['EN', 'ES', 'DE', 'FR'],
      },
    ],
    sameAs: [
      consts.socialUrls.twitter.url ?? '',
      consts.socialUrls.facebook.url ?? '',
      consts.socialUrls.linkedIn.url ?? '',
      consts.socialUrls.youTube.url ?? '',
      consts.socialUrls.instagram.url ?? '',
    ],
  })
}
